import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import '@rainbow-me/rainbowkit/styles.css';

import './App.css';
import { Header } from './components/header';
import { HomePage } from './page/home';
import {
  connectorsForWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import { createConfig, WagmiProvider, http } from 'wagmi'

import {
  base,
  baseSepolia
} from 'wagmi/chains'
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import { rainbowWallet, walletConnectWallet, trustWallet, metaMaskWallet, coinbaseWallet } from '@rainbow-me/rainbowkit/wallets'
import { CHAIN } from './constant'
import { fallback } from 'viem'

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, rainbowWallet, coinbaseWallet, walletConnectWallet, trustWallet, ],
    },
  ],
  {
    appName: 'JockeyBot App',
    projectId: '9dd976e19c2119e33a52207f69533f7c',
  }
);
const queryClient = new QueryClient();

console.log('CHAIN', CHAIN)
const config = createConfig({
  connectors,
  chains: [CHAIN],
  transports: {
    [base.id]: fallback([
      http('https://base-mainnet.g.alchemy.com/v2/Tlgcnm0nsc-gfkNz04D6X98twaAhkqfP'),
      // http('https://mainnet.infura.io/v3/dsadas'),
      http()
    ]),

    [baseSepolia.id]: fallback([
      http('https://base-sepolia.g.alchemy.com/v2/RBqpk44awsJCeWGnOenhM72yfogG7KOq'),
      // http('https://sepolia.infura.io/v3/dasdas'),
      http()
    ])
  },
})


function App() {

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<HomePage/>} />
            </Routes>
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>

  );
}

export default App;
