import {
  base,
  baseSepolia,
} from 'wagmi/chains'
export const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) || 1;

export const CHAIN = CHAIN_ID === base.id ? base : baseSepolia
export const NFT_ADDRESS =
  process.env.REACT_APP_NFT_ADDRESS ||
  '0x48b667e6384c112a150028105bff0419de1503a8';

