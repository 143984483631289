/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { NFT_ADDRESS } from '../../constant'
import { NFT_ABI } from '../../constant/abis'
import { TransactionAlert } from '../transactionAlert'
import styles from './Mint.module.scss'
import {
    useAccount,
    useReadContract,
    useReadContracts,
    useWaitForTransactionReceipt,
    useWriteContract,
} from 'wagmi'
import { ContractFunctionExecutionError, formatEther, parseEther, TransactionExecutionError } from 'viem'


export const Mint: React.FC = () => {
    const { isConnected: active, address: account, chain } = useAccount()
    const [minted, setMinted] = useState<number>(-1)
    const [amount, setAmount] = useState<number>(1)
    const [requestedNftCount, setRequestedNftCount] = useState<number>(1)
    const [maxMintable, setMaxMintable] = useState<number>(10)
    const [mintPrice, setMintPrice] = useState<number>(-1)
    const [isMintInProcess, setIsMintInProcess] = useState<boolean>(false)
    const [freeNft, setFreeNft] = useState<number>(0)

    const contractOptions = {
        abi: NFT_ABI,
        address: NFT_ADDRESS as `0x${string}`,
        chainId: chain?.id,
    }

    const mintNFT = async () => {
        toast.dismiss()

        if (!active) {
            toast(
                <TransactionAlert success={false} msg="Please connect wallet first" />,
            )
            return
        }

        const value = Number(mintPrice) * Math.max(amount - Number(freeNft), 0)
        setIsMintInProcess(true)
        setRequestedNftCount(amount)
        try {
            await writeContractAsync({
                ...contractOptions,
                functionName: 'mintNFT',
                args: [amount],
                value: parseEther(value.toString()),
            })
            toast.loading('Minting...')
        } catch (e) {
            let errMsg = 'Sorry, an error occurred'
            setIsMintInProcess(false)

            if (e instanceof TransactionExecutionError && e.message.includes('User rejected the request')) {
                toast.dismiss()
                return
            }

            if (e instanceof ContractFunctionExecutionError) {

                const err: any = e
                if (err.toString().includes('insufficient funds'))
                    errMsg = `You don't have enough funds`
                if (err.toString().includes('Mint not enabled yet'))
                    errMsg = `Mint is not enabled`
                if (err.toString().includes('You are not whitelisted'))
                    errMsg = `You are not whitelisted`
            }
            toast.dismiss()
            toast.error(<TransactionAlert
                msg={errMsg || 'Something went wrong, please retry'}
                success={false}
            />)

            return
        }
    }

    const { data: hash, writeContractAsync } = useWriteContract()
    const { error: mintTransactionError, isSuccess: isMintSuccess, isError: isMintError } = useWaitForTransactionReceipt({
        hash,
    })
    const { data: totalSupplyRequest, refetch: refetchTotalSupply } = useReadContract({
        ...contractOptions,
        functionName: 'totalSupply',
    })

    const { data: freebiesRequest, refetch: refetchFreebies } = useReadContract({
        ...contractOptions,
        functionName: 'freeNft',
        args: [account],
    })

    useEffect(() => {
        if (isMintSuccess && isMintInProcess) {
            toast.dismiss()
            toast(<TransactionAlert
                msg={`You minted ${requestedNftCount} nfts`}
                success={true}
            />)
            setAmount(1)

            refetchTotalSupply?.()
            refetchFreebies?.()
            setIsMintInProcess(false)
        }

        if (isMintError && isMintInProcess) {
            console.log('mintTransactionError', mintTransactionError)
            toast.error(<TransactionAlert
                msg={`Something went wrong, please retry`}
                success={false}
            />)
            setIsMintInProcess(false)
        }

    }, [isMintSuccess, isMintError, mintTransactionError, requestedNftCount, refetchFreebies, refetchTotalSupply, isMintInProcess])

    const { data } = useReadContracts({
        contracts: [
            {
                ...contractOptions,
                functionName: 'mintPrice',
            },
            {
                ...contractOptions,
                functionName: 'MAX_NFT_PER_USER',
            },
            {
                ...contractOptions,
                functionName: 'MAX_NFT_PER_MINT',
            },
        ],
    })

    const [
        mintPriceRequest, maxNftUserRequest, maxNftPerMintRequest] = data || []


    useEffect(() => {
        setFreeNft(freebiesRequest ? Number(freebiesRequest) : 0)
        setMinted(Number(totalSupplyRequest ?? 0))
        const mintPriceResult = mintPriceRequest?.result ? String(mintPriceRequest.result) : 0
        console.log('mintPriceResult', mintPriceResult)
        setMintPrice(Number(formatEther(BigInt(mintPriceResult ?? 0))))
        setMaxMintable(maxNftPerMintRequest?.result ? Number(maxNftPerMintRequest?.result) : 1)
    }, [freebiesRequest, totalSupplyRequest, maxNftUserRequest, mintPriceRequest, isMintSuccess, maxNftPerMintRequest])


    return (
        <div className={styles.page}>
            <div className={styles.logo}>
                <img src="/img/logo_up.svg" alt="" />
            </div>
            <div className={styles.mintPage}>
                <div className={styles.mintImg}>
                    <img src="/img/nft_mint.gif" alt="" />
                </div>
                <div className={styles.mintBox}>
                    <div className={styles.priceBox}>
                        <div>
                            <p>PRICE PER JOCKEYBOT</p>
                            <h4>{mintPrice === -1 ? '-' : Number(mintPrice.toFixed(4))} ETH</h4>
                        </div>
                        <div>
                            <p>TOTAL</p>
                            <h4>
                                {mintPrice === -1 ? '-' : Number((mintPrice * amount).toFixed(4))}{' '}
                                ETH
                            </h4>
                        </div>
                    </div>
                    <div className={styles.title}>
                        MINT YOUR
                        <img src="/img/mint_logo.svg" alt="" />
                    </div>
                    <div className={styles.mintAmount}>
                        <span
                            onClick={() => {
                                setAmount(Math.max(1, amount - 1));
                            }}
                        >
                            -
                        </span>
                        <div className={styles.amount}>{amount}</div>
                        <span
                            onClick={() => {
                                setAmount(Math.min(maxMintable, amount + 1));
                            }}
                        >
                            +
                        </span>
                    </div>
                    <div className={styles.mintPrice}>
                        <p>
                            <span>TOTAL MINTED</span> {minted === -1 ? '-' : minted}
                        </p>
                        <button
                            className={styles.connectBtn}
                            disabled={!active || isMintInProcess}
                            onClick={mintNFT}
                        >
                            MINT
                        </button>
                        <p>
                            <span>FREEBIES</span> {freeNft}
                        </p>

                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <a href={"https://www.jockeybot.com/finish-line"} target={"_blank"} rel="noreferrer">Terms of agreement</a>
            </div>
        </div>
    );
};
