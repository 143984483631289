import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { ConnectButton } from '@rainbow-me/rainbowkit';


export const Header: React.FC = () => {
    return (
        <div className={styles.header}>
            <div className={styles.headerContent}>
                <Link to="/">
                    {/* <img src="/img/logo_menu.png" alt="" /> */}
                </Link>

                <div className={styles.socials}>
                    <ConnectButton />
                </div>
            </div>
        </div>
    );
};
