import styles from './TransactionAlert.module.scss';

export const TransactionAlert: React.FC<{
  msg: string;
  success: boolean;
}> = ({ msg, success }) => (
  <div className={styles.toastContainer}>
    <img
      src={`/img/toast/${success ? 'SUCCESS' : 'ERROR'}.png`}
      width="55px"
      alt=""
    />
    <p>{msg}</p>
  </div>
);
